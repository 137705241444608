import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as d3 from 'd3';
import round from 'lodash/round';

import { List } from 'antd';

import 'antd/es/list/style/index.css';

import {
  getDataInRange,
  scaleRange10ToRange5,
  setUpTrendGraph,
} from '../utils/graph';
import getLocalizedText from '../utils/getLocalizedText';
import { handleTooltipMouseMove } from '../utils/tooltips';

import { TGraphData, TrendGraphProps, TrendSeries } from '../types';
import { TrendGraphPropTypes, TrendSeriesPropType } from '../propTypes';

import SvgTrendPlot from '../SvgTrendPlot';
import '../styles.css';
import './styles.css';

interface Props extends TrendGraphProps {
  trendSeriesList: TrendSeries[];
}

const propTypes = {
  ...TrendGraphPropTypes,
  trendSeriesList: PropTypes.arrayOf(TrendSeriesPropType).isRequired,
};

const getTooltipText = (
  datum: TGraphData,
  trendSeries: TrendSeries,
  language: string,
): string => {
  if (trendSeries.labels) {
    let label = trendSeries.labels[datum.value];

    if (!label) {
      label = trendSeries.labels[scaleRange10ToRange5(datum.value)];
    }

    if (label) {
      return getLocalizedText(label, language);
    }
  }
  return `${round(datum.value)}`;
};

const CombinedTrendGraph = (props: Props) => {
  const {
    timeRange,
    endOfRange,
    trendSeriesList,
    width,
    height,
    xPadding,
    yPadding,
    minimumValue,
    maximumValue,
  } = props;

  const svgRef = useRef(null);
  const { i18n } = useTranslation();

  const startOfRange = endOfRange - timeRange;

  const tooltipHeight = 75;

  const { t } = useTranslation();

  const {
    bisectDate, line, scaleX, scaleY, xAxis,
  } = setUpTrendGraph({
    timeRange,
    endOfRange,
    width,
    height,
    minimumValue,
    maximumValue,
    xPadding,
    yPadding,
  });

  const getSvg = () => d3.select(svgRef.current);

  function onMouseMove(this: any): void {
    handleTooltipMouseMove(
      this,
      scaleX,
      scaleY,
      bisectDate,
      trendSeriesList,
      getTooltipText,
      getSvg(),
      tooltipHeight,
      i18n.language,
    );
  }

  const onMouseOver = (): void => {
    const svg = getSvg();
    svg.select('.trend-graph-focus').style('display', null);
    svg.select('.trend-graph-focus-circle').style('display', null);
  };

  const onMouseOut = (): void => {
    const svg = getSvg();
    svg.select('.trend-graph-focus').style('display', 'none');
    svg.select('.trend-graph-focus-circle').style('display', 'none');
  };

  useEffect(() => {
    const svg = getSvg();

    (svg.select('.axis-line') as d3.Selection<
    SVGGElement,
    unknown,
    null,
    undefined
    >).call(xAxis);

    const dataList = trendSeriesList.map((trendSeries) => ({
      title: getLocalizedText(trendSeries, i18n.language, 'title'),
      data: getDataInRange(trendSeries.data, startOfRange, endOfRange),
    }));

    const lines = svg.selectAll('.trend-series');
    lines.data(dataList);
    lines.attr('d', (d: any) => line(d.data));

    svg.select('.trend-graph-tooltip-overlay').on('mousemove', onMouseMove);
  });

  const seriesPaths = trendSeriesList.map((trendSeries) => (
    <path
      key={trendSeries.id}
      className="trend-series"
      style={{ stroke: trendSeries.color }}
    />
  ));

  const legendInfo = trendSeriesList.map((trendSeries) => ({
    title: getLocalizedText(trendSeries, i18n.language, 'title'),
    color: trendSeries.color,
  }));

  return (
    <>
      <h2 className="trend-title">{t('trendsDashboard:combined-header')}</h2>
      <svg ref={svgRef} width={width} height={height}>
        <SvgTrendPlot
          height={height}
          width={width}
          xPadding={xPadding}
          yPadding={yPadding}
          showGuideText
          showTooltipTitle
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
          {seriesPaths}
        </SvgTrendPlot>
      </svg>
      <section className="legend-div">
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={legendInfo}
          renderItem={(entry) => (
            <div className="legend-entry">
              <svg className="legend-span" width={20} height={5}>
                <rect width={20} height={5} style={{ fill: entry.color }} />
              </svg>
              {entry.title}
            </div>
          )}
        />
      </section>
    </>
  );
};

CombinedTrendGraph.propTypes = propTypes;

export default CombinedTrendGraph;
