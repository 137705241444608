import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import includes from 'lodash/includes';
import map from 'lodash/map';

import { List, Radio, Space } from 'antd';

import 'antd/es/list/style/index.css';

import { TrendSeries } from '../types';
import { scaleRange5ToRange10 } from '../utils/graph';
import getLocalizedText from '../utils/getLocalizedText';

import TrendSelector, { StatusInfo } from '../TrendSelector';
import TrendGraph from '../TrendGraph';
import CombinedTrendGraph from '../CombinedTrendGraph';
import RangeController from '../RangeController';

import '../styles.css';

interface Props {
  onRangeUpdate: (timeRange: number, endOfRange: number) => void;
  timeRange: number;
  endOfRange: number;
  enabledTrends: string[];
  trendSeriesList: TrendSeries[];
}

const propTypes = {
  onRangeUpdate: PropTypes.func,
  timeRange: PropTypes.number,
  endOfRange: PropTypes.number,
  enabledTrends: PropTypes.arrayOf(PropTypes.string).isRequired,
  trendSeriesList: PropTypes.arrayOf(PropTypes.object),
};

// Get only trends using a 1-5 or 1-10 scale.
const getCombinedTrendSeriesList = (
  trendSeriesList: TrendSeries[],
): TrendSeries[] => {
  const relevantSeriesList = trendSeriesList.filter(
    (trendSeries) => trendSeries.minimumValue === 1
      && (trendSeries.maximumValue === 5 || trendSeries.maximumValue === 10),
  );

  return relevantSeriesList.map((trendSeries) => {
    if (trendSeries.maximumValue === 10) {
      return trendSeries;
    }

    const data = trendSeries.data.map((datum) => ({
      ...datum,
      value: scaleRange5ToRange10(datum.value),
    }));

    return { ...trendSeries, data };
  });
};

const getRelevantStatuses = (
  relevantSeriesList: TrendSeries[],
  statuses: Map<string, StatusInfo>,
  language: string,
): Map<string, StatusInfo> => {
  const relevantStatuses = new Map();

  relevantSeriesList.forEach((trendSeries) => relevantStatuses.set(
    trendSeries.id,
    statuses.get(trendSeries.id) || {
      enabled: true,
      title: getLocalizedText(trendSeries, language, 'title'),
    },
  ));

  return relevantStatuses;
};

const TrendsDashboard = ({
  onRangeUpdate,
  enabledTrends,
  trendSeriesList,
  timeRange,
  endOfRange,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [splitView, setSplitView] = useState(true);

  const [statuses, setStatuses] = useState(undefined as undefined | Map<string, StatusInfo>);

  if (!statuses) {
    const defaultTrendStatuses = new Map<string, StatusInfo>(
      map(trendSeriesList, (trendSeries) => [
        trendSeries.id,
        {
          enabled: includes(enabledTrends, trendSeries.id),
          title: getLocalizedText(trendSeries, i18n.language, 'title'),
        },
      ]),
    );

    setStatuses(defaultTrendStatuses);
    return <></>;
  }

  let relevantSeriesList: TrendSeries[];
  let relevantStatuses: Map<string, StatusInfo>;

  if (!splitView) {
    relevantSeriesList = getCombinedTrendSeriesList(trendSeriesList);
    relevantStatuses = getRelevantStatuses(
      relevantSeriesList,
      statuses,
      i18n.language,
    );
  } else {
    relevantSeriesList = trendSeriesList;
    relevantStatuses = new Map(statuses);
  }

  const enabledTrendSeriesList = relevantSeriesList.filter(
    (trendSeries) => statuses.get(trendSeries.id)?.enabled,
  );

  const toggleTrends = (trends: [string, boolean][]): void => {
    if (!statuses) {
      return;
    }

    const newTrendStatuses = new Map(statuses);
    trends.forEach(([id, enabled]) => {
      const { title } = newTrendStatuses.get(id) as StatusInfo;
      newTrendStatuses.set(id, { enabled, title });
    });
    setStatuses(newTrendStatuses);
  };

  return (
    <section className="trends-dashboard">
      <section className="trends-panel centered-wrapper separator-div print-hideable">
        <RangeController
          onRangeUpdate={onRangeUpdate}
          initialTimeRange={timeRange}
          initialEndOfRange={endOfRange}
        />
        <section className="split-view-panel">
          <Space>
            <Radio.Group
              defaultValue={splitView}
              buttonStyle="solid"
              onChange={(e) => setSplitView(e.target.value)}
            >
              <Radio.Button value={false}>{t('trendsDashboard:combined')}</Radio.Button>
              <Radio.Button value>{t('trendsDashboard:separate')}</Radio.Button>
            </Radio.Group>
          </Space>
        </section>
      </section>
      <section className="centered-wrapper">
        <TrendSelector
          trendStatuses={relevantStatuses}
          trendsToggled={toggleTrends}
        />
      </section>
      { splitView
        ? (
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
            dataSource={enabledTrendSeriesList}
            renderItem={(trendSeries) => (
              <List.Item>
                <TrendGraph
                  trendSeries={trendSeries}
                  endOfRange={endOfRange}
                  timeRange={timeRange}
                  minimumValue={trendSeries.minimumValue}
                  maximumValue={trendSeries.maximumValue}
                  xPadding={30}
                  yPadding={20}
                  width={475}
                  height={250}
                />
              </List.Item>
            )}
          />
        )
        : (
          <CombinedTrendGraph
            endOfRange={endOfRange}
            timeRange={timeRange}
            trendSeriesList={enabledTrendSeriesList}
            minimumValue={1}
            maximumValue={10}
            xPadding={30}
            yPadding={20}
            width={900}
            height={400}
          />
        ) }
    </section>
  );
};

TrendsDashboard.whyDidYouRender = true;
TrendsDashboard.propTypes = propTypes;

export default TrendsDashboard;
