import * as d3 from 'd3';
import clamp from 'lodash/clamp';
import { addDays } from 'date-fns';

import { FORMAT_TOOLTIP_DATE } from '../constants';
import { Point, TGraphData, TrendSeries } from '../types';
import { TrafficLight } from '../clinical_types';
import getLocalizedText from './getLocalizedText';

export const TOOLTIP_X_OFFSET = 10;

export const handleTooltipMouseMove = (
  context: any,
  scaleX: d3.ScaleTime<number, number>,
  scaleY: d3.ScaleLinear<number, number>,
  bisectDate: d3.Bisector<TGraphData, unknown>,
  trendSeriesList: TrendSeries[],
  generateTooltipText: (
    datum: TGraphData,
    trendSeries: TrendSeries,
    language: string,
  ) => string,
  svg: d3.Selection<null, unknown, null, undefined>,
  tooltipHeight: number,
  language: string,
): void => {
  if (trendSeriesList.length === 0) {
    return;
  }

  const [mouseX, mouseY] = d3.mouse(context);
  const width = parseInt(svg.attr('width'), 10);
  const height = parseInt(svg.attr('height'), 10);
  // Get date corresponding to mouse x pixel position.
  const mouseXDate = scaleX.invert(mouseX);

  const points: Point[] = [];

  // Get points that could be hovered over.
  trendSeriesList.forEach((trendSeries) => {
    const index = bisectDate.left(trendSeries.data, mouseXDate, 1);

    if (index <= 0 || index >= trendSeries.data.length) {
      return;
    }

    const getPoint = (i: number): Point => {
      const datum = trendSeries.data[i];
      return new Point(
        clamp(scaleX(datum.date) || 0, 0, width) as number,
        scaleY(datum.value) as number,
        trendSeries.id,
        datum.labelValue,
      );
    };

    points.push(getPoint(index - 1));
    points.push(getPoint(index));
  });

  if (points.length <= 0) {
    return;
  }

  // Get point closest to mouse.
  const point = new Point(mouseX, mouseY).getClosestPoint(points);

  if (!point) {
    return;
  }

  const datum: TGraphData = {
    date: scaleX.invert(point.x).getTime(),
    value: scaleY.invert(point.y),
    labelValue: point.labelValue,
  };

  const date = FORMAT_TOOLTIP_DATE(new Date(datum.date));
  const trendSeries = trendSeriesList.filter(
    (series) => series.id === point.id,
  )[0];

  const titleText = getLocalizedText(trendSeries, language, 'title');
  const valueText = generateTooltipText(datum, trendSeries, language);
  const characterWidth = 7.75;
  const tooltipWidth = Math.max(100, valueText.length * characterWidth);

  const tooltipX = clamp(point.x, 0, width - tooltipWidth - TOOLTIP_X_OFFSET);
  const tooltipY = clamp(point.y, 0, height - tooltipHeight);

  const focus = svg.select('.trend-graph-focus');

  focus.attr('transform', `translate(${tooltipX}, ${tooltipY})`);
  svg
    .select('.trend-graph-focus-circle')
    .attr('transform', `translate(${point.x}, ${point.y})`);
  focus.select('.trend-graph-tooltip-title').text(titleText);
  focus.select('.trend-graph-tooltip-date').text(date);
  focus.select('.trend-graph-tooltip-value').text(valueText);
  focus.select('.trend-graph-tooltip').attr('width', tooltipWidth);
};

export const hideElement = (className: string) => {
  d3.select(`.${className}`).style('display', 'none');
};

export const handleClinicalTooltipMouseMove = (
  context: any,
  tableHeaderWidth: number,
  lightWidth: number,
  lightHeight: number,
  trafficLightGrid: TrafficLight[][],
  tooltipWidth: number,
  tooltipHeight: number,
  startOfRange: number | Date,
) => {
  const getTooltip = () => d3.select('.clinical-tooltip-svg');

  const hideTooltip = () => {
    getTooltip().style('display', 'none');
  };

  const showTooltip = () => {
    getTooltip().style('display', null);
  };

  const [mouseX, mouseY] = d3.mouse(context);

  if (mouseX < tableHeaderWidth) {
    hideTooltip();
    return;
  }

  const day = Math.trunc((mouseX - tableHeaderWidth) / lightWidth);
  const trend = Math.trunc(mouseY / lightHeight);

  if (
    trafficLightGrid[trend] === undefined
    || trafficLightGrid[trend][day] === undefined
    || trafficLightGrid[trend][day] === TrafficLight.Empty
  ) {
    hideTooltip();
    return;
  }

  const x = tableHeaderWidth + day * lightWidth;
  const y = trend * lightHeight + lightHeight / 2;

  const tooltip = getTooltip();

  tooltip.style('left', `${x - tooltipWidth / 2 + lightWidth / 2}`);
  tooltip.style('top', `${y - tooltipHeight / 2 + lightHeight / 2}`);

  d3.select('.clinical-date-tooltip').text(
    FORMAT_TOOLTIP_DATE(addDays(startOfRange, day)),
  );

  showTooltip();
};
