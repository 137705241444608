import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import './styles.css';

interface Props {
  title: string;
  children?: ReactNode;
}

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const printPage = (): void => {
  const hideableElements = d3.selectAll('.print-hideable');
  hideableElements.style('display', 'none');
  window.print();
  hideableElements.style('display', 'block');
};

const PageHeader = ({ title, children }: Props) => {
  const { t } = useTranslation();

  return (
    <section className="trends-report-div print-hideable">
      <section className="centered-wrapper">
        <section className="title-area">
          <h1 className="page-title">{title}</h1>
          <Button className="print-button" type="primary" onClick={printPage}>
            {t('trendsDashboard:printPage')}
          </Button>
        </section>
        {children}
      </section>
    </section>
  );
};

PageHeader.propTypes = propTypes;

export default PageHeader;
