export type TLocalizable<T> = {
  'en-GB': T;
  'nl-NL': T;
};

export type TLocalizedText = TLocalizable<string>;

export interface TrendInfo {
  clinical: boolean;
  enabledTrends: string[];
  seriesList: TrendSeries[];
}

export type Aggregation = 'latest' | 'average' | 'cumulative';

export interface TrendSeries {
  id: string;
  title: TLocalizedText;
  type: 'generic' | 'temperature' | 'weight' | 'psa' | 'bp' | 'numeric';
  minimumValue: number;
  maximumValue: number;
  defaultValue: number;
  step: number;
  decimals?: number;
  order: number;
  color: string;
  labels?: {
    [index: string]: TLocalizedText;
  };
  labelUnit?: TLocalizedText;
  data: TGraphData[];
  graphConfig?: {
    showThumbs: boolean;
    data: Aggregation;
  }
}

export type TGraphData = {
  date: number;
  value: number;
  labelValue?: number;
  selectable?: boolean;
  implicit?: boolean;
};

export interface TrendGraphProps {
  timeRange: number;
  endOfRange: number;
  width: number;
  height: number;
  xPadding: number;
  yPadding: number;
  minimumValue: number;
  maximumValue: number;
}

export class Point {
  x: number;

  y: number;

  id: string;

  labelValue: number;

  constructor(x = 0, y = 0, id = '', labelValue = 0) {
    this.x = x;
    this.y = y;
    this.id = id;
    this.labelValue = labelValue;
  }

  squaredDistance(point: Point): number {
    return (point.x - this.x) ** 2 + (point.y - this.y) ** 2;
  }

  getClosestPoint(points: Point[]): Point {
    let closestPoint = points[0];
    let closestDistance = this.squaredDistance(points[0]);

    for (let i = 1; i < points.length; i += 1) {
      const point = points[i];
      const testDistance = this.squaredDistance(point);

      if (testDistance < closestDistance) {
        closestPoint = point;
        closestDistance = testDistance;
      }
    }

    return closestPoint;
  }
}
