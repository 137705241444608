import map from 'lodash/map';

import {
  TGraphData,
  TrendSeries,
  TrendInfo,
} from '../components/TrendGraphs/types';

const getJson = async (
  response: Response,
  errorMessage?: string,
): Promise<any> => {
  if (response.ok) {
    const json = await response.json();
    return json;
  }

  throw new Error(errorMessage);
};

type TrendDataDto = {
  clinical: boolean;
  enabledTrends: string[];
  trends: { [id: string]: TGraphData[] };
};

const getTrendData = async (documentId: string, key: string): Promise<TrendDataDto> => {
  const url = `${window.location.origin}/data/${documentId}/${key}.json`;

  const response = await fetch(url);

  return getJson(response, 'No trend data found');
};

export const getTrendIndicators = async (documentId: string): Promise<any> => {
  const url = `${window.location.origin}/data/${documentId}/trend_indicators.json`;

  const response = await fetch(url);

  return getJson(response, 'No trend indicator configuration found');
};

export const getTrendSeriesList = async (documentId: string, key: string): Promise<TrendInfo> => {
  const trendDataPromise = getTrendData(documentId, key);
  const trendIndicatorsPromise = getTrendIndicators(documentId);

  const trendDataDto = await trendDataPromise;
  const trendIndicators = await trendIndicatorsPromise;

  const trendSeriesList: TrendSeries[] = [];
  const trendData: { [id: string]: TGraphData[] } = trendDataDto.trends;

  Object.keys(trendData).forEach((id): void => {
    const data = trendData[id];

    if (!(id in trendIndicators)) {
      return;
    }

    const {
      color,
      defaultValue,
      labelUnit,
      labels,
      maximumValue,
      minimumValue,
      order,
      step,
      title,
      type,
      decimals,
      graphConfig,
    } = trendIndicators[id] as TrendSeries;

    trendSeriesList.push({
      color,
      data,
      defaultValue,
      id,
      labelUnit,
      labels,
      maximumValue,
      minimumValue,
      order,
      step,
      title,
      type,
      decimals,
      graphConfig,
    });
  });

  return {
    clinical: trendDataDto.clinical,
    enabledTrends: map(trendSeriesList, (trendSeries) => trendSeries.id),
    seriesList: trendSeriesList,
  };
};
