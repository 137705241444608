import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { endOfDay, getTime } from 'date-fns';

import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { processTimeData } from '../components/TrendGraphs/utils/graph';
import { getTrendSeriesList } from '../services/trends_api';
import { getDocumentIdAndKey, getRouteWithShareKey } from '../utils/browser_util';
import routes from '../routes';

import ClinicalDashboard from '../components/TrendGraphs/ClinicalDashboard';
import TrendsDashboard from '../components/TrendGraphs/TrendsDashboard';
import { TrendInfo, MONTH } from '../components/TrendGraphs';
import PageHeader from '../components/PageHeader';

interface Props {
  showClinical: boolean;
}

const propTypes = {
  showClinical: PropTypes.bool,
};

const TrendsReportHeader = ({ showClinical }: Props) => {
  const { t } = useTranslation();

  return (
    <PageHeader title={t('trendsDashboard:trendsTitle')}>
      <p className="trends-description">
        {t('trendsDashboard:trendsDescription')}
      </p>
      {showClinical && (
      <Button type="primary">
        <Link to={getRouteWithShareKey(routes.clinicalDasbhoard)}>{t('trendsDashboard:viewClinical')}</Link>
      </Button>
      )}
    </PageHeader>
  );
};

const ClinicalDashboardHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader title={t('trendsDashboard:trendsTitle')}>
      <p className="trends-description">
        {t('trendsDashboard:clinicalDescription')}
      </p>
      <Button type="primary">
        <Link to={getRouteWithShareKey(routes.trendsDashboard)}>{t('trendsDashboard:backToTrends')}</Link>
      </Button>
    </PageHeader>
  );
};

const TrendDataSource = ({ showClinical }: Props): JSX.Element => {
  const [state, setState] = useState({
    timeRange: MONTH,
    endOfRange: getTime(endOfDay(new Date())),
  });

  const [trendInfo, setTrendInfo] = useState(undefined as TrendInfo | undefined);

  const updateTrendInfo = ({
    clinical,
    enabledTrends,
    seriesList,
  }: TrendInfo): void => {
    processTimeData(seriesList);
    seriesList.sort(
      (trendSeries1, trendSeries2) => trendSeries1.order - trendSeries2.order,
    );

    setTrendInfo({
      clinical,
      enabledTrends,
      seriesList,
    } as TrendInfo);
  };

  if (!trendInfo) {
    const { documentId, key } = getDocumentIdAndKey();
    getTrendSeriesList(documentId, key)
      .then(updateTrendInfo)
      .catch(() => {
        setTrendInfo({
          clinical: false,
          enabledTrends: [],
          seriesList: [],
        });
      });
    return showClinical ? <ClinicalDashboardHeader /> : <TrendsReportHeader showClinical={false} />;
  }

  const onRangeUpdate = (timeRange: number, endOfRange: number) => (
    setState({ timeRange, endOfRange })
  );

  if (!trendInfo.clinical && showClinical) {
    return <Redirect to={getRouteWithShareKey(routes.trendsDashboard)} />;
  }

  if (showClinical) {
    return (
      <>
        <ClinicalDashboardHeader />
        <ClinicalDashboard trendSeriesList={trendInfo.seriesList} />
      </>
    );
  }

  return (
    <>
      <TrendsReportHeader showClinical={trendInfo.clinical} />
      <section className="centered-wrapper">
        <TrendsDashboard
          timeRange={state.timeRange}
          endOfRange={state.endOfRange}
          onRangeUpdate={onRangeUpdate}
          enabledTrends={trendInfo.enabledTrends}
          trendSeriesList={trendInfo.seriesList}
        />
      </section>
    </>
  );
};

TrendsReportHeader.propTypes = propTypes;
TrendDataSource.propTypes = propTypes;

export default TrendDataSource;
